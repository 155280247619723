export default async function getMenuData() {
  return [
    // Dashboard

    {
      adminOnly: false,
      hideEpcc: false,
      pmcOnly: false,
      contractorOnly: false,
      title: 'DOCUMENT MANAGER',
      key: 'DocumentManager',
      url: '/DocumentManager/DocumentListing',
    },
    {
      category: true,
      title: 'ASSIGNMENT',
    },
    {
      adminOnly: true,
      hideEpcc: true,
      pmcOnly: false,
      contractorOnly: false,
      title: 'ASSIGNMENT',
      key: 'assignment',
      url: '/DocumentManager/Assignment'
    },
    {
      category: true,
      title: 'MY REVIEWS',
    },
    {
      adminOnly: false,
      hideEpcc: true,
      pmcOnly: false,
      contractorOnly: false,
      title: 'MY REVIEWS',
      key: 'my_reviews',
      url: '/DocumentManager/MyReviews'
    },
    {
      category: true,
      title: 'UNDER REVIEW',
    },
    {
      adminOnly: true,
      hideEpcc: true,
      pmcOnly: false,
      contractorOnly: false,
      title: 'UNDER REVIEW',
      key: 'under_review',
      url: '/DocumentManager/UnderReview'
    },
    {
      category: true,
      title: 'CONSOLIDATION',
    },
    {
      adminOnly: true,
      hideEpcc: true,
      pmcOnly: true,
      contractorOnly: false,
      title: 'CONSOLIDATION',
      key: 'document_consolidation',
      url: '/DocumentManager/Consolidation'
    },
    {
      category: true,
      title: 'CLOSING',
    },
    {
      adminOnly: true,
      hideEpcc: true,
      pmcOnly: false,
      contractorOnly: true,
      title: 'CLOSING',
      key: 'document_closing',
      url: '/DocumentManager/Closing'
    },
    {
      category: true,
      title: 'APPROVALS',
    },
    {
      adminOnly: true,
      hideEpcc: true,
      pmcOnly: false,
      contractorOnly: false,
      title: 'APPROVALS',
      key: 'approvals',
      url: '/DocumentManager/MyApprovals'
    },
    {
      adminOnly: false,
      hideEpcc: false,
      pmcOnly: false,
      contractorOnly: false,
      title: 'ARCHIVED DOCUMENTS',
      key: 'ArchivedDocuments',
      url: '/DocumentManager/ArchivedDocuments',
    },
    {
      adminOnly: true,
      hideEpcc: false,
      pmcOnly: false,
      contractorOnly: false,
      title: 'ADMINISTRATION',
      key: 'Administration',
      children: [
        {
          title: 'MANAGE FOLDERS',
          key: 'manage_folders',
          url: '/Administration/ManageDocumentFolder',
        },
        {
          title: 'MANAGE APPLICATION USERS',
          key: 'application_users',
          url: '/Administration/ManageApplicationUsers',
        },
        {
          title: 'PREFERENCES',
          key: 'preferences',
          url: '/Administration/Preferences',
        }, {
          title: 'MANAGE LOOKUP',
          key: 'lookup',
          url: '/Administration/ManageLookup',
        },
      ],
    },
  ];
}
