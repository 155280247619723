import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.softblue.compact.css';
import 'antd/lib/style/index.less'; // antd core styles
import './components/kit/vendors/antd/themes/default.less'; // default theme antd components
import './components/kit/vendors/antd/themes/dark.less'; // dark theme antd components
import './global.scss'; // app & third-party component styles

import React from 'react';
import ReactDOM from 'react-dom';
import {createHashHistory} from 'history';
import {createStore, applyMiddleware, compose} from 'redux';
import {Provider} from 'react-redux';

// import { logger } from 'redux-logger'
import createSagaMiddleware from 'redux-saga';
import {routerMiddleware} from 'connected-react-router';

import reducers from './redux/reducers';
import sagas from './redux/sagas';
import Localization from './localization';
import Router from './router';
import * as serviceWorker from './serviceWorker';
import config from 'devextreme/core/config';
import dxTextArea from "devextreme/ui/text_area";
import dxTextBox from "devextreme/ui/text_box";
// middlewared
const history = createHashHistory();
const sagaMiddleware = createSagaMiddleware();
const routeMiddleware = routerMiddleware(history);
const middlewares = [sagaMiddleware, routeMiddleware];
// if (process.env.NODE_ENV === 'development') {
//   middlewares.push(logger)
// }


config({
  editorStylingMode: 'filled'
});

const store = createStore(reducers(history), compose(applyMiddleware(...middlewares)));
sagaMiddleware.run(sagas);

dxTextArea.defaultOptions({
  options: {
    onInitialized(e) {
      if (e.component.option("readOnly")) return
      if (e.component.option("disabled")) return
      e.component.option("spellcheck", true)
    }
  }
});

dxTextBox.defaultOptions({
  options: {
    onInitialized(e) {
      if (e.component.option("readOnly")) return
      if (e.component.option("disabled")) return
      e.component.option("spellcheck", true)
    }
  }
});

ReactDOM.render(
  <Provider store={store}>
    <Localization>
      <Router history={history}/>
    </Localization>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
export {store, history};
