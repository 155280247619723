import React, {lazy, Suspense} from 'react';
import {Route, Redirect, Switch} from 'react-router-dom';
import {ConnectedRouter} from 'connected-react-router';
import {CSSTransition, SwitchTransition} from 'react-transition-group';
import {connect} from 'react-redux';
import store from 'store';
import Layout from 'layouts';

const routes = [];

//#region Dashboard


//#endregion

//#region Document Manager
routes.push({
    path: '/DocumentManager/DocumentListing',
    Component: lazy(() => import('RetroDCS/DocumentManager/ViewDefault')),
    exact: true,
  }, {
    path: '/DocumentManager/Assignment',
    Component: lazy(() => import('RetroDCS/DocumentManager/ViewAssignment')),
    exact: true,
  }, {
    path: '/DocumentManager/UnderReview',
    Component: lazy(() => import('RetroDCS/DocumentManager/ViewUnderReview')),
    exact: true,
  }, {
    path: '/DocumentManager/Consolidation',
    Component: lazy(() => import('RetroDCS/DocumentManager/ViewConsolidation')),
    exact: true,
  }, {
    path: '/DocumentManager/Closing',
    Component: lazy(() => import('RetroDCS/DocumentManager/ViewClosing')),
    exact: true,
  }, {
    path: '/DocumentManager/DocumentReviewAndConsolidationSyncFusion',
    Component: lazy(() => import('RetroDCS/DocumentManager/DocumentReviewAndConsolidationSyncFusion')),
    exact: true,
  }, {
    path: '/DocumentManager/DocumentReviewAndConsolidationPdfjs',
    Component: lazy(() => import('RetroDCS/DocumentManager/DocumentReviewAndConsolidationPdfJs')),
    exact: true,
  }, {
    path: '/DocumentManager/MyReviews',
    Component: lazy(() => import('RetroDCS/DocumentManager/MyViewReview')),
    exact: true,
  }, {
    path: '/DocumentManager/MyApprovals',
    Component: lazy(() => import('RetroDCS/DocumentManager/MyViewApprovals')),
    exact: true,
  }, {
    path: '/DocumentManager/ArchivedDocuments',
    Component: lazy(() => import('RetroDCS/DocumentManager/ViewArchivedDocuments')),
    exact: true,
  }
);


//#endregion

//#region Administration
routes.push({
  path: '/Administration/ManageDocumentFolder',
  Component: lazy(() => import('RetroDCS/Administration/ManageDocumentFolder')),
  exact: true,
});

routes.push({
  path: '/Administration/ManageApplicationUsers',
  Component: lazy(() => import('RetroDCS/Administration/ManageApplicationUsers')),
  exact: true,
});

routes.push({
  path: '/Administration/ManageLookup',
  Component: lazy(() => import('RetroDCS/Administration/ManageLookups/index')),
  exact: true,
});
//#endregion

//#region Utilities
routes.push(
  {
    path: '/auth/login',
    Component: lazy(() => import('pages/auth/login')),
    exact: true,
  },
  {
    path: '/auth/forgot-password',
    Component: lazy(() => import('pages/auth/forgot-password')),
    exact: true,
  },
  {
    path: '/auth/register',
    Component: lazy(() => import('pages/auth/register')),
    exact: true,
  },
  {
    path: '/auth/lockscreen',
    Component: lazy(() => import('pages/auth/lockscreen')),
    exact: true,
  },
  {
    path: '/auth/404',
    Component: lazy(() => import('pages/auth/404')),
    exact: true,
  },
  {
    path: '/auth/500',
    Component: lazy(() => import('pages/auth/500')),
    exact: true,
  },
);

//#endregion

const mapStateToProps = ({settings}) => ({
  routerAnimation: settings.routerAnimation,
});

const redirectToDashboard = () => {
  return <Redirect to="/DocumentManager/DocumentListing"/>;
};

const Router = ({history, routerAnimation}) => {
  return (
    <ConnectedRouter history={history}>
      <Layout>
        <Route
          render={state => {
            const {location} = state;
            return (
              <SwitchTransition>
                <CSSTransition
                  key={location.pathname}
                  appear
                  classNames={routerAnimation}
                  timeout={routerAnimation === 'none' ? 0 : 300}
                >
                  <Switch location={location}>
                    {/* Check for redirection */}
                    <Route exact path="/" render={redirectToDashboard}/>
                    {routes.map(({path, Component, exact}) => (
                      <Route
                        path={path}
                        key={path}
                        exact={exact}
                        render={() => {
                          return (
                            <div className={routerAnimation}>
                              <Suspense fallback={null}>
                                <Component/>
                              </Suspense>
                            </div>
                          );
                        }}
                      />
                    ))}
                    <Redirect to="/auth/404"/>
                  </Switch>
                </CSSTransition>
              </SwitchTransition>
            );
          }}
        />
      </Layout>
    </ConnectedRouter>
  );
};

export default connect(mapStateToProps)(Router);
